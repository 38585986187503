import { PaymentElement, useCustomCheckout } from '@stripe/react-stripe-js';
import { type StripeCustomCheckoutRecurring } from '@stripe/stripe-js';
import { add, format } from 'date-fns';
import pluralize from 'pluralize';
import { type FormEvent, useState } from 'react';
import { useEffectOnce } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoProduct,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { useOrgAnalytics } from '../../analytics/organization';
import { getEnv } from '../../config/getEnv';
import { marketing } from '../../config/marketing';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type Organization } from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import { sleep } from '../../utils/common';
import { formatCurrency } from '../../utils/currency';
import { EmailIcon2 } from '../icons/EmailIcon';
import { LockIcon } from '../icons/LockIcon';
import { StarIcon } from '../icons/StarIcon';
import { UnlockIcon } from '../icons/UnlockIcon';
import { Loading } from '../Loading';
import { CompaniesUsingLunaPark } from '../Marketing/CompaniesUsingLunaPark';
import { SocialProofJennifer } from '../Marketing/SocialProof';
import { Discount } from '../Payment/Discount';
import { HeadcountEditor } from '../Payment/Headcount';
import { StripeCustomCheckoutProvider } from '../Payment/StripeCustomCheckoutProvider';
import { ProductUtils } from './utils';

function sendConversionEvent(
  trial: boolean,
  enabled = marketing(getEnv()).registrationMarketingTrackingEnabled
) {
  if (!enabled) return;

  if (trial) {
    gtag('event', 'conversion', {
      send_to: 'AW-10811798990/VzQICO2vnbMZEM7ru6Mo',
      value: 1.0,
      currency: 'USD',
    });
  }
}

function CheckoutForm(props: {
  product: DtoProduct;
  price: ModelsPrice;
  returnUrl: string;
}) {
  const { product, price, returnUrl } = props;

  const { confirm, canConfirm, recurring } = useCustomCheckout();
  const analytics = useOrgAnalytics();

  const {
    call: handleSubmit,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async (event: FormEvent) => {
    event.preventDefault();

    analytics.trackSubscriptionPaymentSubmitted({
      productId: product.id,
      productName: product.name,
      priceId: price.id,
      priceAmount: price.amount,
      billingInterval: price.billingInterval,
      maxSeat: price.maxSeats,
    });

    const { error } = await confirm();
    if (error) {
      analytics.trackSubscriptionPaymentFailed({
        productId: product.id,
        productName: product.name,
        priceId: price.id,
        priceAmount: price.amount,
        billingInterval: price.billingInterval,
        maxSeat: price.maxSeats,
        errType: error.type,
        errCode: error.code,
        errMsg: error.message,
      });

      throw new Error(error.message);
    }

    sendConversionEvent(!!recurring?.trial);

    // wait for 5 seconds to ensure the subscription is updated.
    await sleep(5000);
    window.location.replace(returnUrl);
  });

  const [ready, setReady] = useState(false);

  return (
    <form
      onSubmit={handleSubmit}
      className='
        w-140 2xl:w-160 rounded-2.5xl bg-payment bg-cover px-18 py-10
        backdrop-blur-sm border border-[#6e6e6e] flex flex-col gap-7.5
      '
    >
      <p className='text-2xl font-medium text-center text-white'>
        {recurring && recurring.trial
          ? 'Start Your Free Trial'
          : 'Finalize Your Subscription'}
      </p>

      <PaymentElement
        options={{
          layout: 'tabs',
        }}
        onReady={() => setReady(true)}
        className='text-white'
      />

      <div>
        {error && (
          <div className='mb-1 w-full text-sms text-red-002 text-center'>
            {error.message}
          </div>
        )}
        <button
          type='submit'
          disabled={isRunning || !ready || !canConfirm}
          className={`btn-primary font-bold w-full h-12.5 flex justify-center items-center gap-2 ${
            ready ? '' : 'hidden'
          }`}
        >
          {isRunning && <Loading text='' />}
          {recurring && recurring.trial && recurring.trial.trialPeriodDays > 0
            ? `Start ${recurring.trial.trialPeriodDays} Day Free Trial`
            : `Subscribe Now`}
        </button>
        <div className='mt-2 flex justify-center items-center text-icon-gray'>
          <LockIcon className='w-3 h-3 fill-current' />
          <p className='ml-1 text-xs'>Secure payments powered by</p>
          <img
            src={getStaticAssetPath('images/marketing/stripe-logo.png')}
            alt=''
            className='ml-1 w-9 h-4 object-contain'
          />
        </div>
      </div>
    </form>
  );
}

function HowYourTrialWorks() {
  const { recurring } = useCustomCheckout();
  const trialPeriodDays = recurring?.trial?.trialPeriodDays;

  if (!trialPeriodDays || trialPeriodDays < 3) return null;

  const now = new Date();
  const emailReminder = format(
    add(now, { days: trialPeriodDays - 2 }),
    'MMM d'
  );
  const trialEnd = format(add(now, { days: trialPeriodDays }), 'MMM d');

  return (
    <div className='w-70'>
      <div className='text-white font-bold text-sm pb-3'>
        How your free trial works
      </div>
      <div className='pl-[10px]'>
        <div className='relative border-l-[4px] border-[#D9D9D9] pl-6 pb-6'>
          <div
            className='absolute top-0 left-0 w-6 h-6 rounded-full bg-green-001 text-black flex items-center justify-center'
            style={{
              transform: 'translate(calc(-50% - 2px), -1px)',
            }}
          >
            <UnlockIcon className='w-3 h-3 fill-current' />
          </div>
          <p className='text-white font-bold text-sm'>
            Today: Get Instant Access
          </p>
          <p className='text-icon-gray text-sms'>
            100s of team-building, onboarding, well-being, and seasonal
            experiences, and a lot more!
          </p>
        </div>
        <div className='relative border-l-[4px] border-[#D9D9D9] pl-6 pb-6'>
          <div
            className='absolute top-0 left-0 w-6 h-6 rounded-full bg-[#D9D9D9]  text-black flex items-center justify-center'
            style={{
              transform: 'translate(calc(-50% - 2px), -1px)',
            }}
          >
            <EmailIcon2 className='w-3 h-3 fill-current' />
          </div>
          <p className='text-white font-bold text-sm'>
            Day {trialPeriodDays - 2}: Trial Reminder
          </p>
          <p className='text-icon-gray text-sms'>
            We’ll send a reminder on {emailReminder}. To avoid unwanted charges,
            cancel any time before your trial ends.
          </p>
        </div>
        <div className='relative border-l-[4px] border-transparent pl-6'>
          <div
            className='absolute top-0 left-0 w-6 h-6 rounded-full bg-[#D9D9D9]  text-black flex items-center justify-center'
            style={{
              transform: 'translate(calc(-50% - 2px), -1px)',
            }}
          >
            <StarIcon className='w-3 h-3 fill-current' />
          </div>
          <p className='text-white font-bold text-sm'>
            Day {trialPeriodDays}: Trial Ends
          </p>
          <p className='text-icon-gray text-sms'>
            Your subscription will start on {trialEnd} and you will be billed
            every {recurring.intervalCount}{' '}
            {pluralize(recurring.interval, recurring.intervalCount)} thereafter.
          </p>
        </div>
      </div>
    </div>
  );
}

function duration(recurring: StripeCustomCheckoutRecurring): Duration {
  switch (recurring.interval) {
    case 'day':
      return { days: recurring.intervalCount };
    case 'week':
      return { weeks: recurring.intervalCount };
    case 'month':
      return { months: recurring.intervalCount };
    case 'year':
      return { years: recurring.intervalCount };
  }
}

function InstantQuote() {
  const { total, recurring } = useCustomCheckout();

  if (!recurring) return null;

  if (recurring?.trial) {
    const firstBillDue = format(
      add(new Date(), { days: recurring.trial.trialPeriodDays }),
      'yyyy/MM/dd'
    );

    return (
      <div className='bg-dark-gray rounded-lg p-4.5 text-white text-sms'>
        <div className='text-sms flex flex-col items-end gap-1'>
          <div>
            {pluralize('day', recurring.trial?.trialPeriodDays, true)} free
          </div>

          <div>
            Auto-renews every{' '}
            {pluralize(recurring.interval, recurring.intervalCount, true)}
          </div>
        </div>

        <div className='my-2.5 border-b border-secondary' />

        <div className='text-sms space-y-1'>
          <div className='flex items-baseline'>
            <div className='flex-1'>Subtotal</div>
            <div className='flex-none text-right tabular-nums'>
              {formatCurrency(recurring.dueNext.amountSubtotal / 100, true)}
            </div>
          </div>

          <Discount />
        </div>

        <div className='my-2.5 border-b border-secondary' />

        <div className='text-sms space-y-1'>
          <div className='flex items-baseline'>
            <div className='flex-1'>Total after trial</div>
            <div className='flex-none text-right tabular-nums'>
              {formatCurrency(
                (recurring.dueNext.amountSubtotal -
                  recurring.dueNext.amountDiscount) /
                  100,
                true
              )}
            </div>
          </div>

          <div className='flex items-baseline font-bold'>
            <div className='flex-1'>Total due today</div>
            <div className='flex-none text-right tabular-nums'>
              {formatCurrency(total.total / 100, true)}
            </div>
          </div>

          <div className='flex items-baseline'>
            <div className='flex-1'>First bill due</div>
            <div className='flex-none text-right tabular-nums'>
              {firstBillDue}
            </div>
          </div>

          <div className='pt-4 text-icon-gray text-3xs text-center'>
            Visit the billing section to cancel any time prior to your next
            billing date to avoid that cycle’s charge. We do not provide
            refunds.
          </div>
        </div>
      </div>
    );
  }

  const nextBillDue = format(
    add(new Date(), duration(recurring)),
    'yyyy/MM/dd'
  );

  return (
    <div className='bg-dark-gray rounded-lg p-4.5 text-white text-sms'>
      <div className='flex-none text-right tabular-nums'>
        Auto-renews every{' '}
        {pluralize(recurring.interval, recurring.intervalCount, true)}
      </div>

      <div className='my-2.5 border-b border-secondary' />

      <div className='text-sms space-y-1'>
        <div className='flex items-baseline'>
          <div className='flex-1'>Subtotal</div>
          <div className='flex-none text-right tabular-nums'>
            {formatCurrency(total.subtotal / 100, true)}
          </div>
        </div>

        <Discount />
      </div>

      <div className='my-2.5 border-b border-secondary' />

      <div className='flex items-baseline font-bold'>
        <div className='flex-1'>Total due today</div>
        <div className='flex-none text-right tabular-nums'>
          {formatCurrency(total.total / 100, true)}
        </div>
      </div>

      <div className='flex items-baseline'>
        <div className='flex-1'>Next bill due</div>
        <div className='flex-none text-right tabular-nums'>{nextBillDue}</div>
      </div>

      <div className='pt-4 text-icon-gray text-3xs text-center'>
        Visit the billing section to cancel any time prior to your next billing
        date to avoid that cycle’s charge. We do not provide refunds.
      </div>
    </div>
  );
}

const sslBadge = getStaticAssetPath('images/checkout-ssl-badge.png');
const moneybackBadge = getStaticAssetPath(
  'images/checkout-moneyback-badge.png'
);
const gdprBadge = getStaticAssetPath('images/checkout-gdpr-badge.png');

function CheckoutBadges() {
  return (
    <div className='w-full flex items-center justify-center gap-2 h-10'>
      <img src={sslBadge} alt='SSL' className='h-full w-auto' />
      <img src={moneybackBadge} alt='Moneyback' className='h-full w-auto' />
      <img src={gdprBadge} alt='GDPR' className='h-full w-auto' />
    </div>
  );
}

function Sidebar(props: {
  product: DtoProduct;
  price: ModelsPrice;
  headcount: number;
  onHeadcountChange: (headcount: number) => void;
  fixed?: boolean;
}) {
  const { product, price, headcount, onHeadcountChange, fixed = true } = props;

  const pricesByInterval = ProductUtils.ActivePricesByBillingInterval(product);
  const prices = pricesByInterval.get(price.billingInterval) ?? [];

  return (
    <div
      className={`
        ${
          fixed ? 'fixed' : 'absolute'
        } top-0 right-0 w-75 2xl:w-85 h-full overflow-auto scrollbar bg-modal text-white
      `}
    >
      <div className='mt-10 2xl:mt-14 w-full px-6 2xl:px-9'>
        <div className='text-3.5xl font-black font-Montserrat text-tertiary text-center'>
          {`${product.name} Membership`}
        </div>

        <div className='mt-4'>
          <HeadcountEditor
            headcount={headcount}
            options={prices.map((p) => p.maxSeats)}
            onHeadcountChange={onHeadcountChange}
          />
        </div>

        <div className='mt-4'>
          <InstantQuote />
        </div>

        <div className='mt-4'>
          <CheckoutBadges />
        </div>
      </div>
    </div>
  );
}

export interface SubscriptionPaymentProps {
  organization: Organization;
  product: DtoProduct;
  price: ModelsPrice;
  headcount: number;
  trial: boolean;
  trialDays?: number | null;
  // relative url
  returnUrl: string;

  onHeadcountChange: (headcount: number) => void;
  fixedSidebar?: boolean;
}

function parseTrialDays(q: string | null) {
  if (!q) return null;
  return parseInt(q) || null;
}

export function SubscriptionPayment(props: SubscriptionPaymentProps) {
  const {
    organization,
    product,
    price,
    headcount,
    trial,
    trialDays = parseTrialDays(getQueryParam('td')),
    returnUrl,
    onHeadcountChange,
    fixedSidebar = true,
  } = props;

  const { data: clientSecret } = useSWRImmutable(
    [
      `/subscription/payment`,
      organization.id,
      product.id,
      price.id,
      trial,
      trialDays,
    ],
    async () => {
      const resp =
        await apiService.organization.createSubscriptionCheckoutSession(
          organization.id,
          {
            productId: product.id,
            priceId: price.id,
            trial,
            trialDays,
            uiMode: 'custom',
            returnUrl,
          }
        );
      return resp.data.clientSecret;
    }
  );

  useEffectOnce(() => {
    apiService.organization.upsertSubscriptionIntent(organization.id, {
      productId: product.id,
      priceId: price.id,
      trial,
      path: window.location.pathname,
      queries: new URLSearchParams(window.location.search).toString(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  });

  if (!clientSecret) return <Loading />;
  return (
    <StripeCustomCheckoutProvider clientSecret={clientSecret}>
      <Sidebar
        product={props.product}
        price={price}
        headcount={headcount}
        onHeadcountChange={onHeadcountChange}
        fixed={fixedSidebar}
      />

      <div className='relative'>
        <div className='absolute right-full mr-5 top-0 space-y-5 hidden lg:flex flex-col items-center'>
          {trial && <HowYourTrialWorks />}
          <SocialProofJennifer />
        </div>

        <CheckoutForm
          product={product}
          price={price}
          returnUrl={props.returnUrl}
        />
        <div
          className={`mt-9 w-full flex flex-col items-center gap-2.5 filter grayscale`}
        >
          <p className='text-sms text-icon-gray text-center italic'>
            Hundreds of companies subscribe to Luna Park to elevate their
            cultures
          </p>
          <CompaniesUsingLunaPark />
        </div>
      </div>
    </StripeCustomCheckoutProvider>
  );
}
